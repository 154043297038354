/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/scrollmonitor@1.2.4/scrollMonitor.min.js
 * - /npm/letteringjs@0.7.0/jquery.lettering.min.js
 * - /npm/fittext@1.0.1/dist/jquery.fittext.js
 * - /npm/textillate@0.4.1/jquery.textillate.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
